'use client'
import { FooterBlock } from 'blocks/Footer'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Paragraph } from 'components/Paragraph'
import Switch from 'components/icons/Switch'
import { Link, Locale } from 'i18n/routing'
import { useLocale, useTranslations } from 'next-intl'
import { useEffect } from 'react'
import tailwindConfig from 'tailwind.config'

export default function NotFound() {
  const t = useTranslations('error')
  const locale = useLocale() as Locale

  useEffect(() => {
    document.title = 'farbenmeer | 404'
    const themeColorTag: HTMLMetaElement =
      document.head.querySelector('meta[name="theme-color"') ??
      document.createElement('meta')
    themeColorTag.name = 'theme-color'
    themeColorTag.content = tailwindConfig.theme.colors.black
    document.head.appendChild(themeColorTag)
  }, [])

  return (
    <main className="grid" id="main">
      <style>{`
          body {
            --background-color: ${tailwindConfig.theme.colors.black};
            --highlight-color: ${tailwindConfig.theme.colors.white};
            --wave-color: ${tailwindConfig.theme.colors.orange};
            color: ${tailwindConfig.theme.colors.white};
          }
        `}</style>
      <section className="pb-48 lg:pb-160 pt-80 lg:pt-96 highlight-orange bg-black text-white">
        <Container className="lg:grid lg:grid-cols-3 xl:grid-cols-4 gap-16 items-center justify-center lg:px-128">
          <div className=" fill-orange px-80 lg:pl-0 pb-32 lg:self-start justify-center flex">
            <Switch width="100%" height="100%" className="max-w-224" />
          </div>
          <div className="col-span-2">
            <h1>{t('pageNotFound')}</h1>
            <h2 className="text-orange lg:ml-48 mt-16 mb-40 text-20 lg:text-27 -rotate-2">
              <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 807 96"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
              >
                <title>Error 404</title>
                <path d="m-82-113h1024v600h-1024z" fill="none" />
                <g fillRule="nonzero" fill="currentColor">
                  <path d="m56.895 25.334c1.74.14 4.18.23 6.58.56s3.68 3.84 2.04 5.81c-.99 1.2-2.28 2.51-3.69 2.93-1.88.57-4.02.5-6.04.41-11.17-.51-21.67 2.9-32.23 5.72-6.13 1.64-7.74 7.66-4.25 13.87.9 1.6 2.48 1.87 4.21 1.7 5.26-.54 10.52-1.1 15.79-1.49 1.58-.12 3.22.21 4.81.49 1.76.31 2.72 3.63 1.58 5.03-1.61 1.98-3.69 3.27-6.13 3.73-5.2.98-10.4 2.02-15.66 2.53-4.43.43-7.51 2.08-8.56 8.58-.41 2.56 1.28 5.04 3.86 5.29 5.25.51 10.51.94 15.78 1.11 3.78.12 7.24.91 10.36 3.06 1.94 1.34 1.81 5.04-.34 6.28-1.4.8-2.88 1.67-4.42 1.94-2.39.41-4.86.32-7.3.48-8.1.53-16.25-.17-24.31 1.64-4.37.98-8.53-4.14-7.52-8.67.97-4.36 2.22-8.66 3.13-13.04 1.91-9.14 4.11-18.26 3.21-27.75-.15-1.61-.05-3.26.1-4.88.42-4.8 2.71-7.17 7.76-7.74 8.89-1.01 17.69-2.26 26.07-5.66 4.62-1.89 9.61-1.48 15.17-1.93z" />
                  <path d="m283.805 86.334c-10.6-.92-21.28-5.53-28.05-17.2-1.68-2.89-2.74-5.76-2.84-9.14-.37-12.56 5.34-21.73 16.67-27.19 12.35-5.96 24.02-3.71 34.34 3.93 7.19 5.32 9.28 13.98 9.15 22.85-.03 1.98-.84 3.95-1.28 5.93-3.07 13.9-11.68 20.86-27.99 20.82zm3.44-11.38c.41-.05 2.88-.11 5.18-.75 3.19-.88 5.93-2.69 7.41-5.82 3.74-7.89 3.43-15.64-1.22-23.07-1.36-2.17-3.55-3.58-6.12-3.24-4.48.6-8.74.25-13.18-.71-6.42-1.39-12.49 2.28-14.1 8.55-3.35 13.05.07 22.17 16.38 25.01 1.18.21 2.42.03 5.65.03z" />
                  <path d="m519.195 30.744c.79-4.89-1.31-9.4.53-13.74.43-1.01 1.5-2.16 2.48-2.39.88-.2 2.35.51 3 1.28 2.14 2.57 3.06 5.65 3.13 9.03.1 4.88.17 9.77.61 14.62.49 5.25 1.29 10.47 2.16 15.67.39 2.32 3.25 4.2 5.6 3.6 5.5-1.4 10.95-3.01 16.37-4.7 2.19-.68 3.22-2.6 2.92-4.76-.32-2.38-1.35-4.65-1.73-7.02-.31-1.96-.32-4.03-.1-6 .2-1.75 1.66-2.82 3.26-3.26 1.7-.47 3.23.29 4.24 1.68 2.46 3.38 3.18 7.4 4.12 11.36.66 2.78 2.67 3.74 5.62 3.02 5.12-1.25 10.21-2.61 15.34-3.81 3.31-.77 5.34.41 6.73 3.62 1.09 2.52-.04 5.29-2.56 5.8-2.77.56-5.59.83-8.39 1.26-2.41.37-4.85.62-7.2 1.22-3.64.93-5.4 3.3-5.35 7.09.08 5.29.39 10.54 1.99 15.67.96 3.1 1.48 6.33.32 9.58-.62 1.72-1.92 2.64-3.57 2.92-1.75.3-3.38-.6-4.15-2.01-2.36-4.36-3.3-9.18-3.44-14.09-.37-13.52-2.51-14.92-15.37-10.25-6.22 2.26-12.62 3.04-19.18 2.67-2.68-.15-4.59-1.87-4.81-4.56-.91-11.29-1.74-22.62-2.57-33.5z" />
                  <path d="m685.825 46.624c0 1.15.01 1.96 0 2.78-.21 15.47-6.34 22.61-21.51 26.76-10.96 3-20.87.71-30.38-4.87-5.29-3.11-8.36-7.67-9.23-13.65-.76-5.26-1.12-10.64.06-15.81 2.53-11.08 8.69-19.05 20.24-22.02 4.33-1.11 8.56-2.57 12.85-3.82 4.13-1.2 8.1-.95 11.59 1.79 5.41 4.23 11.25 7.87 14 14.87 1.86 4.72 2.19 9.44 2.38 13.97zm-26.48-19.21c-4.21-1.16-8.23 1.73-12.63 2.05-5.14.38-7.93 3.55-10.18 7.62-2.88 5.23-3.24 10.92-2.27 16.57.86 5.02 3.79 8.7 8.71 10.89 6.22 2.77 12.59 3.08 19.06 1.8 12.16-2.4 18.84-13.79 15.4-25.88-2.59-9.13-8.09-13.19-18.09-13.05z" />
                  <path d="m91.305 82.484c.25-2.02.54-4.85.95-7.66.35-2.41 1.08-4.79 1.19-7.21.82-17.94.95-11.6 7.82-27.82 2.46-5.81 6.99-8.83 12.39-10.97 1.86-.74 4.03-.67 5.95-1.31 6.34-2.1 11.04.67 15.18 4.99 2.94 3.08 3.67 14.12.13 18.57-2.6 3.26-5.58 5.92-9.1 8.02-2.08 1.24-4.39 2.11-6.48 3.34-1.19.7-1.35 2.2-.58 3.16 1.52 1.88 3.27 3.6 5.06 5.22 5.43 4.92 10.99 9.71 16.39 14.67 1.96 1.8 2.3 4.2 1.42 6.74-.85 2.44-3.63 3.82-5.86 3.04-2.79-.98-4.87-2.81-6.82-4.96-4.37-4.83-8.7-9.7-13.35-14.25-3.18-3.11-6.84-5.73-10.34-8.5-1.09-.86-3.81.2-3.98 1.55-.35 2.81-.52 5.65-.76 8.47-.28 3.23-.44 6.47-.9 9.67-.28 1.97-2.92 3.6-4.6 3.09-1.67-.51-2.77-1.7-3.16-3.38-.29-1.19-.32-2.42-.55-4.47zm28.59-47.8c-3.14-.14-6 1.02-8.43 3.51-2.31 2.37-4.04 5.05-5.3 8.08-1.74 4.19 1.39 8.83 5.74 8.15 5.29-.82 10.14-2.83 13.98-6.74 2.29-2.33 2.63-5.63 1.15-9.03-1.33-3.03-3.77-4.04-7.14-3.97z" />
                  <path d="m372.375 20.534c.05 0 2.95-.03 5.7.59 6.24 1.4 10.19 7.8 8.81 13.95-1.28 5.71-3.79 10.57-8.66 14.29-4.45 3.39-4.08 4.86.95 7.83 3.86 2.28 7.75 4.49 11.63 6.72 4.64 2.68 8.51 6.29 12.13 10.18 1.3 1.4 1.03 4.1-.39 5.59-1.23 1.29-2.9 1.95-4.39 1.15-2.85-1.53-5.74-3.17-8.15-5.28-9.38-8.19-20.81-12.72-31.73-18.16-3.2-1.59-6.63.67-6.92 4.49-.18 2.38.35 4.81.43 7.23.05 1.58.02 3.2-.26 4.75-.34 1.84-3.42 3.07-4.94 2.13-2.92-1.81-5.27-4.17-4.73-7.86 1.35-9.26 2.75-18.52 4.54-27.71.93-4.78 2.66-9.4 5.86-13.31 3.24-3.96 6.99-6.83 12.44-6.59 1.61.07 3.25.01 7.68.01zm-10.94 24.78c5.93-.32 10.86-2.82 14.82-7.26 1.55-1.74 1.65-4.79.31-6.85-1.43-2.21-3.46-3.5-6.14-3.04-2.4.41-4.8.96-7.09 1.78-4.52 1.61-7.54 6.22-7.45 11.5.02.98 1.06 2.22 1.97 2.84.95.64 2.31.69 3.58 1.03z" />
                  <path d="m744.265 18.204c-.65 4.86-.25 10.58-1.67 16.2-1 3.95 2.07 7.54 6.03 7.26 5.65-.39 11.27-1.2 16.88-2.03 1.76-.26 2.63-1.89 2.87-3.57.45-3.2.55-6.45 1.17-9.61.18-.93 1.54-2.11 2.51-2.29.95-.17 2.38.53 3.12 1.31 1.08 1.14 2.02 2.6 2.47 4.09.45 1.51.1 3.24.31 4.85.54 4.12 2.44 5.48 6.62 5.14 3.72-.3 7.27-1.22 10.44-3.14 3.37-2.03 6.34-.26 7.79 3.09.48 1.12 1 2.23 1.42 3.37.57 1.53-.56 3.46-2.19 3.65-6.86.77-13.74 1.36-20.58 2.27-4.58.61-5.5 1.54-5.73 6.31-.33 6.83-2.63 13.63-.33 20.56.48 1.44.06 3.23-.15 4.84-.28 2.13-1.42 3.81-3.39 4.75-1.65.79-4.21-1.15-5.6-3.69-2.54-4.63-2.14-9.52-1.2-14.31.86-4.44 1.33-8.85 1.2-13.34-.08-2.7-1.89-4.61-4.64-4.51-4.87.19-9.74.6-14.59 1.02-2.42.21-4.82.82-7.24.94-3.71.18-6.97-3.15-7.02-6.92-.01-.81-.06-1.65.13-2.43 2.28-9.2 1.72-18.61 2.11-27.95.14-3.25-.01-6.51 1.59-9.54.98-1.85 1.93-3.6 4.32-3.7 1.67-.07 3.27 1.89 3.33 4.38.1 4.07.02 8.14.02 13z" />
                  <path d="m218.625 44.624c.41 6.62-2.29 12.94-9.39 17.18-2.13 1.27-2.22 3.21-.4 4.9 4.78 4.42 9.72 8.66 14.54 13.03 2.15 1.95 2.33 4.5.63 6.5-1.71 2.01-4.38 2.55-6.54 1.03-1.97-1.39-3.8-3.01-5.59-4.64-6.01-5.46-11.93-11.02-17.97-16.44-2.4-2.15-5.76-.8-6.36 2.53-.43 2.39-.57 4.83-.77 7.25-.17 2.03-.14 4.07-.36 6.09-.28 2.54-1.3 4.76-3.86 5.74-1.84.71-4.81-2.82-5.01-5.92-.08-1.21-.22-2.51.12-3.63 3.27-10.96 1.9-22.73 5.97-33.53-1.23-8.98 4.42-11.96 11.72-13.97 4.44-1.23 8.61-1.77 13.14-.47 7.53 2.15 9.98 5.07 10.13 14.35zm-8.95 2.21c.3-3.76-2.46-7.29-5.38-7.77-3.72-.61-7.18.32-10.33 2.13-3.16 1.82-4.54 7.33-3.24 9.47 1.87 3.07 5.72 4.77 9.31 3.33 2.57-1.03 5.08-2.37 7.35-3.95 1.18-.83 1.79-2.49 2.29-3.21z" />
                </g>
              </svg>
            </h2>
            <Paragraph longcopy className="mb-40">
              {t('somethingWentWrong')}
            </Paragraph>
            <Link href="/">
              <Button className="bg-white text-black">
                {t('toStartPage')}
              </Button>
            </Link>
          </div>
        </Container>
      </section>
      <FooterBlock locale={locale} />
    </main>
  )
}
