export default function LinkedIn({ ...rest }: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_194_24689)">
        <path
          d="M7.18998 8.79102H3.57646C3.41608 8.79102 3.28613 8.92102 3.28613 9.08134V20.6901C3.28613 20.8504 3.41608 20.9804 3.57646 20.9804H7.18998C7.35036 20.9804 7.48031 20.8504 7.48031 20.6901V9.08134C7.48031 8.92102 7.35036 8.79102 7.18998 8.79102Z"
          fill="black"
        />
        <path
          d="M5.38448 3.02002C4.06966 3.02002 3 4.08852 3 5.40188C3 6.71583 4.06966 7.78474 5.38448 7.78474C6.69825 7.78474 7.76704 6.71577 7.76704 5.40188C7.7671 4.08852 6.69825 3.02002 5.38448 3.02002Z"
          fill="black"
        />
        <path
          d="M16.3812 8.50244C14.9299 8.50244 13.857 9.12634 13.2063 9.83525V9.08129C13.2063 8.92097 13.0763 8.79096 12.9159 8.79096H9.45536C9.29499 8.79096 9.16504 8.92097 9.16504 9.08129V20.69C9.16504 20.8504 9.29499 20.9803 9.45536 20.9803H13.061C13.2214 20.9803 13.3513 20.8504 13.3513 20.69V14.9464C13.3513 13.0109 13.877 12.2569 15.2262 12.2569C16.6956 12.2569 16.8124 13.4657 16.8124 15.046V20.6901C16.8124 20.8504 16.9423 20.9804 17.1027 20.9804H20.7097C20.87 20.9804 21 20.8504 21 20.6901V14.3225C21 11.4445 20.4512 8.50244 16.3812 8.50244Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_194_24689">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
