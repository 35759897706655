import { BackToTopButton } from 'components/BackToTopButton'
import { ContactInfo } from 'components/ContactInfo'
import { Container } from 'components/Container'
import { Overline } from 'components/Overline'
import { Logo } from 'components/icons/Logo'
import { Link, Locale } from 'i18n/routing'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import mapIllustration from 'public/illustrations/footer-map.svg'
import tailwindConfig from 'tailwind.config'
import styles from './Footer.module.css'
import LinkedIn from 'components/icons/LinkedIn'
import Instagram from 'components/icons/Instragram'

interface Props {
  locale: Locale
}

/*
 * IMPORTANT NOTE:
 * The elements below are often aligned in shortcopy paragraphs in the design.
 * Shortcopy does not work for a list of links on mobile because the links
 * are tap targets and their distance from each other is too small if the line
 * height is 26px. Therefore I make them leading-33 on mobile
 * and shortcopy (leading-26) only on desktop.
 */

export function FooterBlock({ locale }: Props) {
  const today = new Date()
  const t = useTranslations()

  return (
    <>
      <footer className="bg-black text-white highlight-orange">
        <Container width="narrow" className={styles.footerGrid}>
          <Logo className={styles.logo} />

          <section className={styles.addressOne}>
            <Overline className="text-12">
              {t('common.office')} Hamburg
            </Overline>
            <address className="leading-33 md:leading-26 not-italic">
              farbenmeer GmbH
              <br />
              Neuer Kamp 30
              <br />
              20357 Hamburg
            </address>
          </section>

          <section className={styles.addressTwo}>
            <Overline className="text-12">{t('common.office')} Berlin</Overline>
            <address className="leading-33 md:leading-26 not-italic">
              farbenmeer GmbH
              <br />
              Köpenicker Straße 148
              <br />
              10997 Berlin
            </address>
          </section>

          <section className={styles.social}>
            <ul className="leading-33 md:leading-26 flex md:flex-col gap-40">
              <li>
                <a
                  role="button"
                  href="https://www.linkedin.com/company/farbenmeer"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex rounded-full bg-white text-black p-8 hover:bg-orange hover:text-white"
                  aria-label="LinkedIn"
                >
                  <LinkedIn className="w-24 h-24 inline-block" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/farbenmeer_gmbh/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex rounded-full bg-white text-black p-8 hover:bg-orange hover:text-white"
                  aria-label="Instagram"
                >
                  <Instagram className="w-24 h-24 inline-block" />
                </a>
              </li>
            </ul>
          </section>

          <figure className={styles.contact}>
            <figcaption>
              <Overline className="text-12">{t('nav.contact')}</Overline>
            </figcaption>
            <ContactInfo
              phone="+49 40 308 590 95"
              email="moin@farbenmeer.de"
              textColor={tailwindConfig.theme.colors.white}
              highlightColor={tailwindConfig.theme.colors.orange}
            />
          </figure>

          <figure className={styles.map}>
            <span className="w-0 grow max-w-384">
              <Link href="/kontakt">
                <Image src={mapIllustration} alt={t('footer.mapToOurOffice')} />
              </Link>
            </span>
          </figure>

          <section className={styles.imprint}>
            <Overline className="text-12">
              © {today.getFullYear()} farbenmeer GmbH
            </Overline>
            <Overline className="text-12">
              <Link href="/impressum" className="hover:underline">
                {t('nav.legal')}
              </Link>
              &nbsp;·&nbsp;
              <Link href="/datenschutz" className="hover:underline">
                {t('nav.privacy')}
              </Link>
            </Overline>
          </section>
        </Container>
      </footer>
      <BackToTopButton />
    </>
  )
}
